<template>
  <AppModal :is-open="isOpen" modalDialogClass="modal-sm">
    <div class="modal-body text-center">
      <h3 class="confirmation-text">{{ confirmText }}</h3>
      <div class="confirmation-buttons">
        <button class="btn btn-green-text-only" @click="reject">{{ rejectLabel }}</button>
        <button class="btn btn-green" @click="confirm">{{ confirmLabel }}</button>
      </div>
    </div>
  </AppModal>
</template>
<script>
import AppModal from './AppModal'
export default {
  components: { AppModal },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    confirmText: {
      type: String,
      required: true,
      default: 'Define your confirm text'
    },
    confirmLabel: {
      type: String,
      default: 'Ya'
    },
    rejectLabel: {
      type: String,
      default: 'Tidak'
    }
  },
  methods: {
    reject() {
      this.$emit('rejected');
    },
    confirm() {
      this.$emit('confirmed');
    }
  }
}
</script>
<style lang="scss" scoped>
h3.confirmation-text {
  font-size:20px;
  margin-bottom:20px;
  line-height: 1.5;
}
.confirmation-buttons {
  display:flex;
  justify-content: space-around;
  align-items: center;
  width:100%;
  button {
    width:40%;
  }
}

</style>
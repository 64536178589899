import { BaseService, BASE_API_URL } from './BaseService'

export default {
  getList: (page = 1, limit = 5, orderBy = '', orderDirection = '') => BaseService.getWithAuth(BASE_API_URL+ `/training/list?page=${page}&size=${limit}&orderBy=${orderBy}&orderDirection=${orderDirection}`),
  getExpiredList: (page = 1, limit = 5, orderBy = '', orderDirection = '') => BaseService.getWithAuth(BASE_API_URL+ `/training/expired-list?page=${page}&size=${limit}&orderBy=${orderBy}&orderDirection=${orderDirection}`),
  getTypeList: () => BaseService.getWithAuth(BASE_API_URL+ `/training/type`),
  generatePdf: () => BaseService.getPdfWithAuth(BASE_API_URL+ `/training/generatepdf`),
  postCreate: (data) => BaseService.postWithAuth(BASE_API_URL+ `/training/create`, data),
  saveAsNew: (data) => BaseService.postWithAuth(BASE_API_URL+ `/training/save-as-new`, data),
  download: (id) => BaseService.getFileWithAuth(BASE_API_URL+ `/training/download?trainingId=${id}`),
  delete: (data) => BaseService.postWithAuth(BASE_API_URL+ `/training/delete`, data),
  update: (data) => BaseService.postWithAuth(BASE_API_URL+ `/training/update`, data),
  upload: (data) => BaseService.postFormDataWithAuth(BASE_API_URL+ `/training/upload`, data),
}

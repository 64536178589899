<template>
  <div class="modal fade" :class="(isOpen) ? 'show' : ''" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="modalDialogClass" role="document">
      <div class="modal-content">
        <button v-if="hasCloseListener" type="button" class="close" @click="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    modalDialogClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('closed');
    }
  },
  computed: {
    hasCloseListener(){
      return this.$attrs && this.$attrs.onClosed
    }
  }
}
</script>
<style lang="scss">
.modal {
  &.show {
    display: block;
    background:rgba(0,0,0,.3);
    width:100%;
    height:100%;
  }
  .modal-dialog {
    max-width: 850px;
    &.modal-sm {
      max-width: 360px;
    }
    .modal-content {
      border-radius: 10px;
      border:0;
      padding:10px;
      overflow:auto;
      max-height:90vh;
    }
  }
}
</style>
<template>
  <div class="app-table">
    <div class="app-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th v-for="(column, index) in columns" :key="index" :width="column.width" :style="'text-align:'+column.align+';'">
              {{ column.label }} 
              <span 
                :class="[
                  'sort-icon', 
                  (orderBy == column.name) ? 'active' : '',
                  (orderBy == column.name && orderDirection == 'ASC') ? 'asc' : '',
                  (orderBy == column.name && orderDirection == 'DESC') ? 'desc' : ''
                ]"
                v-if="column.isSortable" 
                @click="() => setOrderBy(column.name)">
                <svg width="20" height="15">
                  <use xlink:href="@/assets/img/icon-sort-down.svg#sort-icon"></use>
                </svg>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in paginatedData" :key="i">
            <template v-for="(column, i) in columns" :key="i">
              <td v-if="item.hasOwnProperty(column.name)" :align="column.align">
                <template v-if="$slots[column.name]">
                  <slot :name="column.name" :item="item" :value="item[column.name]"></slot>
                </template>
                <template v-else>
                  {{ item[column.name] }}
                </template>
              </td>
              <td v-else :align="column.align">
                <slot :name="column.name" :item="item"></slot>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="app-table-footer">
      <nav aria-label="Page navigation example">
        <ul class="pagination" v-if="pageNumbers.length > 0">
          <li :class="prevPage != null ? 'page-item' : 'page-item disabled'">
            <a v-if="prevPage != null" class="page-link" @click="() => navigateTo(prevPage)"><i class="fa fa-angle-left"></i></a>
            <a v-else class="page-link"><i class="fa fa-angle-left"></i></a>
          </li>
          <template v-for="num in pageNumbersDisplayed" :key="num">
            <li class="page-item" :class="{ 'active': (currentPage == num) }" >
              <a class="page-link" v-if="num != currentPage" @click="(e) => {e.preventDefault();navigateTo(num)}">{{ num }}</a>
              <span class="page-link" v-else>{{ num }}</span>
            </li>
          </template>
          <li :class="nextPage != null ? 'page-item' : 'page-item disabled'">
            <a v-if="nextPage != null" class="page-link" @click="() => navigateTo(nextPage)"><i class="fa fa-angle-right"></i></a>
            <a v-else class="page-link"><i class="fa fa-angle-right"></i></a>
          </li>
        </ul>
      </nav>
      <div v-if="pagination.perPageOptions && pagination.perPageOptions.length > 0" class="app-table-perpage-options">
        <label>Show</label>
        <select class="form-control" @change="setPerPage">
          <option v-for="(option, i) in pagination.perPageOptions" :key="i" :value="option">{{ option }}</option>
        </select>
        <label v-if="pagination.totalData">- &nbsp;{{ pagination.totalData }} Results</label>
        <label v-else>- {{ data.length }} Results</label>
      </div>
    </div>
    <slot/>
  </div>
</template>
<script>
import { onMounted, ref, reactive, computed, watch } from 'vue';
export default {
  name: 'AppTable',
  props: {
    data: {
      required: true,
      default: () => [],
      type: Array
    },
    columns: {
      required: true,
      default: () => [],
      type: Array
    },
    pagination: {
      default: () => {},
      type: Object
    },
    isServerSide: {
      default: false,
      type: Boolean
    }
  },
  emits: ['onLazyLoad'],
  setup(originalProps, { emit }) {
    const props = reactive(originalProps);
    const data = reactive(props.data);
    const orderBy = ref('');
    const orderDirection = ref('');
    const limit = ref((props.pagination.perPage) ? props.pagination.perPage : 5), offset = ref(0);
    const pageNumbers = reactive([]), pageNumbersCount = ref(5);
    const prevPage = ref(null), nextPage = ref(null), currentPage = ref(props.pagination.page ? props.pagination.page : 1);
    const paginatedData = reactive([]);

    function setPageNumbers() {
      const totalPages = props.pagination.totalData ? Math.ceil(props.pagination.totalData / limit.value ) : Math.ceil(props.data.length / limit.value );
      prevPage.value = currentPage.value > 1 ? (currentPage.value - 1) : null
      nextPage.value = currentPage.value < totalPages ? (parseInt(currentPage.value) + 1) : null

      pageNumbers.splice(0);
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i+1);
      }
    }

    function setPaginatedData() {
      paginatedData.splice(0);
      if(props.isServerSide) {
        props.data.forEach(data => {
          paginatedData.push(data);
        })
//        paginatedData.concat(props.data);
      } else {
        props.data.slice(offset.value, limit.value+offset.value).forEach(data => {
          paginatedData.push(data);
        })
      }
    }

    function setOrderBy(columnName) {
      if(orderBy.value !== columnName) {
        orderBy.value = columnName;
        orderDirection.value = 'ASC';
      } else {
        if(orderDirection.value === 'ASC') {
          orderDirection.value = 'DESC';
        } else {
          orderDirection.value = 'ASC';
        }
      }
      if(props.isServerSide) {
        emit("onLazyLoad", dataToBeEmited);
      }
    }

    function navigateTo(pageNum) {
      currentPage.value = pageNum;
      prevPage.value = currentPage.value > 1 ? (currentPage.value - 1) : null;
      nextPage.value = currentPage.value < pageNumbers.length ? (parseInt(currentPage.value) + 1) : null;
      offset.value = (limit.value * pageNum) - limit.value;
      if(props.isServerSide) {
        emit("onLazyLoad", dataToBeEmited);
      } else {
        setPaginatedData();
      }
    }

    function setPerPage(e) {
      offset.value = 0;
      limit.value = parseInt(e.target.value);
      currentPage.value = 1;
      if(props.isServerSide) {
        emit("onLazyLoad", dataToBeEmited);
      } else {
        setPageNumbers();
        setPaginatedData();
      }
    }

    const pageNumbersDisplayed = computed(() => {
      const pageNumbersDisplayed = [];
      if(pageNumbersCount.value < pageNumbers.length) {
        let pageStart;
        if(currentPage.value == 1 || currentPage.value - 1 < 2) {
          pageStart = 1;
        } else if(currentPage.value == pageNumbers.length || pageNumbers.length - currentPage.value < 2) {
          pageStart = pageNumbers.length - (pageNumbersCount.value - 1);
        } else {
          pageStart = currentPage.value - 2;
        }
        for(let i = pageStart; i <= (pageNumbersCount.value + pageStart - 1); i++) {
          pageNumbersDisplayed.push(i);
        }
      } else {
        for(let i = 1; i <= pageNumbers.length; i++) {
          pageNumbersDisplayed.push(i);
        }
      }

      return pageNumbersDisplayed;
    })

    const dataToBeEmited = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        currentPage: currentPage.value,
        orderBy: orderBy.value,
        orderDirection: orderDirection.value
      }
    })

    watch(data, setPaginatedData);
    watch(props, setPageNumbers);
    onMounted(() => { setPageNumbers(), setPaginatedData()})

    return {
      limit,
      pageNumbers,
      pageNumbersDisplayed,
      prevPage,
      nextPage,
      currentPage,
      orderBy,
      orderDirection,
      navigateTo,
      setPerPage,
      setOrderBy,
      paginatedData
    }
  }
}
</script>
<style lang="scss">
@import './../assets/scss/_variable.scss';
.app-table {
  position: relative;
  overflow:hidden;
  .app-table-wrapper {
    overflow-x:auto;
    overflow-y:hidden;
    width:100%;
    display:block;
  }
  .table {
    margin-bottom: 0;
    font-size: 14px;
    width:max-content;
    min-width:100%;
    &.table-striped {
      tbody tr:nth-of-type(odd) {
        background-color: #F9FAFE;
      }
      th, td {
        border:0;
        padding:13px 15px;
        vertical-align: inherit;
        &:first-child {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 25px;
        }
      }
      th {
        white-space:nowrap;
        padding-right:5px;
        border-top:1px solid #eee;
        background-color: #efeff7b3;
        span.sort-icon {
          display:inline-block;
          padding:5px;
          cursor: pointer;
          svg {
            vertical-align:baseline;
            stroke: #333;
          }
          &.active {
            &.desc {
              transform:rotateX(180deg);
            }
            svg {
              stroke: $green;
            }
          }
        }
      }
      td {
        color:$textGrey;
        .actions {
          white-space: nowrap;
          a, button {
            cursor: pointer;
            margin:0 5px;
          }
        }
      }
    }
  }
  .app-table-footer {
    padding:20px;
    @media(min-width:768px) {
      display:flex;
      justify-content: space-between;
      align-items: center;
    }
    .pagination {
      margin:0 auto;
      justify-content: center;
    }
    .app-table-perpage-options {
      display: flex;
      max-width:300px;
      align-items: center;
      justify-content:center;
      @media(max-width:767px) {
        margin: auto;
        margin-top:10px;
      }
      label {
        white-space: nowrap;
        margin: 0;
        color:$textBlack;
        font-size: 14px;
      }
      .form-control {
        margin:0 10px;
        max-width: 75px;
        height:auto;
        padding:5px 15px;
      }
    }
  }
}
</style>
<template>
  <div
    class="loader-overlay"
    :class="[(type==='block') ? 'block-loader' : 'screen-loader', (isActive) ? 'active' : '']"
  >
    <div class="loader">{{ label }}</div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'screen',
      validator: function (value) {
        // The value must match one of these strings
        return ['block', 'screen'].indexOf(value) !== -1
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    label : {
      type: String,
      default: 'Loading...',
    }
  },
  // watch: {
  //   isActive(newVal, oldVal) {
  //     if(this.type === 'screen') {
  //       if(newVal) {
  //         document.body.style.overflow = 'hidden';
  //       } else {
  //         document.body.style.overflow = '';
  //       }
  //     }
  //   }
  // }
}
</script>
<style lang="scss" scoped>
  .loader-overlay {
    width:0;
    height:0;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s;
    &.active {
      width:100%;
      height:100%;
      opacity: 1;
      visibility: visible;
      background:rgba(#fff, .9);
      left:0;
      right:0;
      bottom:0;
      top:0;
      z-index: 99999;
    }

    &.block-loader {
      position: absolute;
    }
    &.screen-loader {
      position: fixed;
    }
    .loader {
      display: block;
      text-align: center;
      position: absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
    }
  }
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import DashboardIndex from '../views/dashboard/Index'
import UserService from '../services/UserService';
import store from '../store';

const authenticationChecker = (to, from, next) => {
  if(store.state.token) {
    UserService.getUser()
      .then(res => {
        store.commit('authSetUserData', res.profile);
        if(to.path == '/login') {
          next('/');
        } else {
          next();
        }
      })
      .catch((err) => {
        if(err.status == 401) {
          store.commit('authClearToken');
          next('/login');  
        } else {
          next();
        }
      })
  } else {
    if(to.path != '/login') {
      next('/login');
    } else {
      next();
    }
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: authenticationChecker
  },
  {
    path: '/',
    name: 'Home',
    component: DashboardIndex,
    meta: {
      layout: 'AppLayoutDashboard'
    },
    beforeEnter: authenticationChecker
  },
  {
    path: '/dashboard',
    name: 'DashboardIndex',
    component: DashboardIndex,
    meta: {
      layout: 'AppLayoutDashboard'
    },
    beforeEnter: authenticationChecker
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
  <div 
    class="text-center alert"
    :class="['alert-'+type, (isFloated) ? 'floated' : '']">
    <span>{{ message }}</span>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      default: 'warning',
      validator: function (value) {
        // The value must match one of these strings
        return ['warning', 'success', 'danger'].indexOf(value) !== -1
      }
    },
    message: {
      type: String,
      default: 'Define your alert message!'
    },
    isFloated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.alert {
  border:0;
  border-radius:10px;
  padding:.7rem 1rem;
  font-size:15px;
  font-weight: 500;
  &.floated {
    transition: .5s opacity;
    position:fixed;
    left:50%;
    transform: translateX(-50%);
    z-index: 9999;
    top:20px;
    max-width:500px;
    width:100%;
  }
}
</style>

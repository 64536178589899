import { createStore } from 'vuex'
import UserService from './../services/UserService'

export default createStore({
  state: {
    API_URL: 'https://esbportal.borneo-indobara.com',
    token: localStorage.getItem('token'),
    userData: JSON.parse(localStorage.getItem('user')),
    loadingData: false,
  },
  mutations: {
    authSetToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    authClearToken(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
    authSetUserData(state, n) {
      state.userData = n;
      localStorage.setItem('user', JSON.stringify(n));
    },
    authClearUserData(state) {
      state.userData = {};
      localStorage.removeItem('user');
    },
    toggleLoading(state) {
      state.loadingData = !state.loadingData;
    },
  },
  actions: {
    login({ commit }, creds) {
      commit('toggleLoading');
      return new Promise((resolve, reject) => {
        UserService.login(creds).then(data => {
          if (data.message) return reject(data.error.message);
          const { token, profile } = data;
          commit('authSetToken', token);
          commit('authSetUserData', profile);
          resolve(profile);
        }).catch(err => {
          reject(err);
        }).finally(() => {
          commit('toggleLoading');
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('authClearToken');
        commit('authClearUserData');
        resolve();
      })
    },
  },
  modules: {
  }
})

<template>
  <section class="modal-body">
    <div class="modal-title">{{title}}</div>
    <slot name="body">
      <form @submit="submitForm">
        <div class="form-group">
          <label for="trainingTypes" :class="{ 'thick': viewOnly }">Jenis Training</label>
          <select class="form-control" id="trainingTypes" v-model="typeId" v-show="!viewOnly">
            <optgroup v-for="(group, index) in trainingTypes" :label="index" :key="index">
              <option v-for="option in group" :value="option.id" :key="option">
                {{ option.name }}
              </option>
            </optgroup>
          </select>
          
          <div v-show="viewOnly">{{typeName}}</div>
          <div v-if="typeError && !typeId" class="error-message">Jenis Training wajib diisi</div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <label for="tglTraining" :class="{ 'thick': viewOnly }">Tanggal Training</label>
              <datepicker
                v-model="tglTraining"
                inputFormat="dd/MM/yyyy"
                v-show="!viewOnly"
                :upperLimit="new Date()"
                />
              
              <div v-show="viewOnly">{{viewDate(tglTraining)}}</div>
              <div v-if="tglTrainingError && !tglTraining" class="error-message">Tanggal Training wajib diisi</div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label for="tglKadaluarsa" :class="{ 'thick': viewOnly }">Tanggal Kadaluarsa</label>
              <datepicker
                v-model="tglKadaluarsa" 
                inputFormat="dd/MM/yyyy"
                :typeable="true"
                :lowerLimit="new Date(new Date().getTime() + (86400000 * 30))"
                :disabled="trainingExist"
                v-show="!viewOnly" 
                />
              
              <div v-show="viewOnly">{{viewDate(tglKadaluarsa)}}</div>                  
              <div v-if="tglKadaluarsaError && !tglKadaluarsa" class="error-message">Tanggal Kadaluarsa wajib diisi</div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label for="trainingDuration" :class="{ 'thick': viewOnly }">Durasi Training</label>
              <div class="row" v-show="!viewOnly">
                <div class="col-5">
                  <input id="trainingDuration" class="form-control m-0 pl-3" min="0" type="number" v-model="trainingDuration"/>
                </div>
                <div class="col-7">
                  <select class="form-control" id="trainingDurationType" v-model="trainingDurationType">
                    <option value="Hari" selected>Hari</option>
                    <option value="Bulan">Bulan</option>
                  </select>
                </div>
              </div>
                <div v-show="viewOnly">{{ trainingDuration }}</div>                  
                <div v-if="trainingDurationError && !trainingDuration" class="error-message">Durasi training wajib diisi</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="penyelenggara" :class="{ 'thick': viewOnly }">Penyelenggara Training</label>
          <input id="penyelenggara" class="m-0 pl-3" type="text" v-model="penyelenggara" v-show="!viewOnly"/>
          
          <div v-show="viewOnly">{{penyelenggara}}</div>
          <div v-if="penyelenggaraError && !penyelenggara" class="error-message">Penyelenggara Training wajib diisi</div>
        </div>
        <div class="form-group">         
          <label for="trainingCertificateNumber" :class="{ 'thick': viewOnly }">No Sertifikat</label>
          <input id="trainingCertificateNumber" class="m-0 pl-3" type="text" v-model="trainingCertificateNumber" v-show="!viewOnly"/>

          <div v-show="viewOnly">{{trainingCertificateNumber}}</div>
          <div v-if="trainingCertificateNumberError && !trainingCertificateNumber" class="error-message">No Sertifikat wajib diisi</div>
        </div>
        <div class="form-group">
          <div class="mb-2 p-0" v-show="!viewOnly">Dokumen</div>              
          <div v-show="!viewOnly">
            <input type="file" id="files" accept="image/*,.pdf,.zip" style="display:none" ref="file" @change="selectFile" />
            <label class="labelUpload" for="files"><img src="@/assets/img/icon-upload.svg"/>Unggah Dokumen</label>
            <span v-show="!fileError" id="file-chosen" class="ml-2">{{fileName}}</span>
            <span v-show="fileError" id="file-chosen" class="ml-2 error-message">{{fileError}}</span>                
          </div>
        </div>

        <div class="form-group" v-if="viewOnly && flow.length > 0">
          <div class="mb-2 p-0 thick">Alur Persetujuan</div>              
          <table class="flow-table">
            <thead>
              <tr>
                <th>Level</th>
                <th>Penyetuju</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(flow, i) in sortedFlow">
                <tr v-if="flow" :key="i">
                  <td>{{ flow.level }}</td>
                  <td>
                    <template v-if="flow.approver">
                      <div v-for="(approver, j) in flow.approver" :key="j">{{ approver.name }}</div>
                    </template>
                  </td>
                  <td>{{ flow.status }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="row mr-2" style="float: right;">
          <div class="form-group mb-0" v-show="!viewOnly">
            <button
              type="button"
              class="btn btn-green-text-only mr-5"
              @click="close"
            >
              Batal
            </button>
            <button
              v-if="edit"
              type="submit"
              class="btn btn-green"
            >
              Save as new
            </button>
            <button
              v-else
              type="submit"
              class="btn btn-green"
            >
              Simpan
            </button>
          </div>
          <div class="form-group mb-0" v-show="viewOnly">
            <button
              type="button"
              class="btn btn-green"
              @click="close"
            >
              Tutup
            </button>
          </div>
        </div>
      </form>
    </slot>
  </section>
</template>
<script>
import TrainingService from '../services/TrainingService'
import useTrainingActions from './../composables/training/useTrainingActions'
import imageCompression from 'browser-image-compression'
import Datepicker from 'vue3-datepicker'
import moment from 'moment';

export default {
  name: 'AppForm',
  components: {
    Datepicker
  },
  props: {
    edit: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
    title: { type: String, default: 'Tambah' },
    editData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    id: 0,
    typeId: '',
    typeName: '',
    typeError: false,
    trainingTypes: {},
    tglTraining: null,
    tglTrainingError: false,
    tglKadaluarsa: null,
    tglKadaluarsaError: false,
    trainingDuration: null,
    trainingDurationType: 'Hari',
    trainingDurationError: false,
    penyelenggara: '',
    penyelenggaraError: false,
    trainingCertificateNumber: '',
    trainingCertificateNumberError: false,
    flow: [],
    selectedFiles: undefined,
    fileName: 'Belum ada dokumen yang diunggah. Pastikan ukuran <5MB',
    fileError: ''
  }),
  async mounted(){
    await this.getTrainingType();

    if(this.edit || this.viewOnly){
      this.id = this.editData.id
      this.typeId = this.editData.trainingTypeId
      this.typeName = this.editData.trainingType
      this.tglTraining= new Date(this.editData.trainingDate)
      this.tglKadaluarsa= (this.editData.trainingExpiredDate != null) ? new Date(this.editData.trainingExpiredDate) : null
      const trainingDurationString = this.editData.trainingDuration.split(" ")
      this.trainingDuration = trainingDurationString[0]
      this.trainingDurationType = trainingDurationString[1]
      this.penyelenggara= this.editData.trainingProvider
      this.trainingCertificateNumber= this.editData.trainingCertificateNumber
      this.flow = this.editData.flow
      this.fileName = this.editData.trainingCertificate
    }
  },
  setup() {
    const { uploadTraining } = useTrainingActions();
    
    return {
      uploadTraining
    }
  },
  computed: {
    trainingExist() {
      return !this.tglTraining;
    },
    sortedFlow() {
      return [...this.flow].sort((a, b) => { if(a && b) return a.level - b.level })
    }
  },
  methods: {
    close() {
      this.id=0
      this.typeId= ''
      this.typeName= ''
      this.typeError= false
      this.tglTraining= ''
      this.tglTrainingError= false
      this.tglKadaluarsa= ''
      this.tglKadaluarsaError= false
      this.penyelenggara= ''
      this.penyelenggaraError= false
      this.trainingCertificateNumber= ''
      this.trainingCertificateNumberError= false
      this.selectedFiles= undefined
      this.fileName= 'Belum ada dokumen yang diunggah. Pastikan ukuran <5MB'
      this.fileError = '',
      this.compressedFile = '',
      this.$emit('close');
    },
    async getTrainingType() {
      let res = await TrainingService.getTypeList()
      this.trainingTypes = res.trainingTypeList
    },
    async selectFile() {
      this.fileError = '';
      this.selectedFiles = this.$refs.file.files[0];
      this.fileName = this.selectedFiles.name;

      let fileType = this.selectedFiles.type.split('/')

      if(fileType[0] == 'image'){
        const options = {
          maxSizeMB: 5,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedBlob = await imageCompression(this.selectedFiles, options);
          this.selectedFiles = new File([compressedBlob], this.fileName);
        } catch (error) {
          this.fileError = 'Dokumen gagal dipilih. Silahkan pilih dokumen lain.'
          this.selectedFiles = undefined
          this.fileName = ''
        }
      }else{
        if(this.selectedFiles.size >= 5000000){
          this.fileError = 'Gagal dipilih. Pastikan ukuran dokumen <5MB.'
          this.selectedFiles = undefined
          this.fileName = ''
        }
      }
    },
    async submitForm(e){
      e.preventDefault();
      if(this.checkComplete()){
        var expiredDate = null;
        if(this.tglKadaluarsa){
          expiredDate = moment(this.tglKadaluarsa).format('YYYY-MM-DD');
        }
        
        let trainingToBeSubmited = {
          id: this.id,
          trainingType:this.typeId,
          trainingDate:moment(this.tglTraining).format('YYYY-MM-DD'),          
          trainingExpiredDate: expiredDate,
          trainingDuration: parseInt(this.trainingDuration),
          trainingDurationType: this.trainingDurationType,
          trainingProvider: this.penyelenggara,
          noCertificate: this.trainingCertificateNumber,
          trainingCertificate: this.fileName
        }
        if(this.edit && !this.selectedFiles){
          this.$emit('confirmed', trainingToBeSubmited);
        }else{
          let upload = await this.uploadTraining(this.selectedFiles)
          if(upload.data){
            trainingToBeSubmited.trainingCertificate= upload.data
            this.$emit('confirmed', trainingToBeSubmited);
          }else{
            this.fileError = 'Unggahan Gagal'
          }
        }
      }
    },
    checkComplete(){
      let fileCheck = false
      if(this.edit && !this.selectedFiles) fileCheck = true
      if(this.selectedFiles) fileCheck = true

      if(!this.typeId) this.typeError = true
      if(!this.tglTraining) this.tglTrainingError = true
      // if(!this.tglKadaluarsa) this.tglKadaluarsaError = true
      if(!this.penyelenggara) this.penyelenggaraError = true
      // if(!this.trainingCertificateNumber) this.trainingCertificateNumberError = true
      if(!fileCheck) this.fileError = 'Dokumen tidak boleh kosong'
      if(this.typeId && this.tglTraining && this.penyelenggara && fileCheck) return true
    },
    viewDate(date){
      console.log(date)
      var formatDate = moment(date).format('DD-MM-YYYY');
      return (formatDate != '01-01-1970' && date != null) ? formatDate : 'Tidak ada masa berlaku';
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .modal {
    &.show {
      display: block;
    }
    .modal-dialog {
      max-width: 850px;
      border-radius: 15px;
    }
  }
  .view-close {
    width: 141px;
    height: 42px;
    left: 984px;
    top: 637px;

    /* Green Primary */

    background: #45AE09;
    border: 1px solid #45AE09;
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .thick{
    font-weight: bold;
  }
</style>

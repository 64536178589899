import axios from 'axios'

export const BASE_API_URL = "https://esbportal.borneo-indobara.com";
export const BaseService = {
  get: async function(url) {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },
  
  post: async function(url, data) {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },

  put: async function(url, data) {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },

  getWithAuth: async function(url) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.get(url, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },
  getFileWithAuth: async function(url) {
    try {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.get(url, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },

  getPdfWithAuth: async function(url) {
    try {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.get(url, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },
  
  postWithAuth: async function(url, data) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.post(url, data, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },

  putWithAuth: async function(url, data) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.put(url, data, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },
  postFormData: async function(url, formData) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
      const response = await axios.post(url, formData, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  },
  postFormDataWithAuth: async function(url, formData) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }
      const response = await axios.post(url, formData, config);
      return response.data;
    } catch(error) {
      throw error.response;
    }
  }
}
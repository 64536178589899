<template>
  <div class="page dashboard-index">
    <div class="app-box">
      <div class="app-box-header">
        <h2 class="app-box-title">Training dan Kompetensi</h2>
        <div class="app-box-actions">
          <button class="btn btn-no-style d-none d-md-inline-flex" @click="generateTrainingPdf" title="Cetak"><img src="@/assets/img/icon-print.svg"/></button>
          <button class="btn btn-green-transparent btn-create" @click="toggleCreateTrainingModal(true)" title="Tambah"><img class="btn-icon" src="@/assets/img/icon-plus.svg" alt="Tambah"/>Tambah</button>
          <AppModal
            modalDialogClass="modal-lg"
            v-if="isOpenCreateTrainingModal"
            :is-open="isOpenCreateTrainingModal"
          >
            <AppForm
              title="Tambah Training dan Kompetensi"
              @close="toggleCreateTrainingModal(false)"
              @confirmed="(trainingToBeSubmited) => {createTraining(trainingToBeSubmited, () => { getTrainingList(); getTrainingExpiredList(); toggleCreateTrainingModal(false) })}"
            />
          </AppModal>

        </div>
      </div>
      <div class="app-box-content">
        <AppTable
          :data="trainingList"
          :columns="[
            {
              name: 'trainingType',
              label: 'Jenis Training',
              width: '250px',
            },
            {
              name: 'trainingCertificateNumber',
              label: 'No Sertifikat',
              width: '140px',
            },
            {
              name: 'trainingDate',
              label: 'Tanggal Training',
              width: '140px',
            },
            {
              name: 'trainingExpiredDate',
              label: 'Berlaku Sampai',
              width: '140px',
              isSortable: true
            },
            {
              name: 'trainingDuration',
              label: 'Durasi Training',
              width: '140px',
              isSortable: true
            },
            {
              name: 'trainingStatus',
              label: 'Status',
              width: '150px',
              isSortable: true,
              align: 'center'
            },
            {
              name: 'actions',
              label: '',
              width: '200px'
            }
          ]"
          :pagination="{
            page: trainingListPage,
            perPage: trainingListPerPage,
            totalData: totalTrainingList,
            perPageOptions: [5, 10],
          }"
          :is-server-side="true"
          @onLazyLoad="(e) => getTrainingList(e.value)"
        >
          <template v-slot:trainingDate="props">
            {{ moment(props.value).format('DD MMM YYYY') }}
          </template>
          <template v-slot:trainingExpiredDate="props">
            {{ (props.value) ? moment(props.value).format('DD MMM YYYY') : 'Tidak ada masa berlaku' }}
          </template>
          <template v-slot:trainingStatus="props">
            <div class="training-status app-badges">
              <span v-if="props.value == '0'" class="badge badge-warning">Menunggu Persetujuan</span>
              <span v-if="props.value == 1" class="badge badge-success">Aktif</span>
              <span v-if="props.value == 2" class="badge badge-danger">Kadaluarsa</span>
              <span v-if="props.value == 3" class="badge badge-danger">Ditolak</span>
              <span v-if="props.value == 3" class="reason-icon ml-2" @click="showRejectedReason(props.item)">
                <img class="reason-icon" src="@/assets/img/icon-reason.svg"/>
                <!-- <div class="reason">
                  <p>Pengajuan ditolak karena : {{props.item.note}}</p>
                </div> -->
              </span>
            </div>
          </template>
          <template v-slot:actions="props">
            <div class="actions">
              <a title="Download" @click="() => downloadTraining(props.item.id, props.item.trainingCertificate)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 }"><img src="@/assets/img/icon-download.svg"/></a>
              <a title="View" @click="() => toggleViewTrainingModal(true, props.item)"><img src="@/assets/img/icon-view.svg"/></a>
              <a title="Edit" @click="() => toggleEditTrainingModal(true, props.item)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 }"><img src="@/assets/img/icon-edit.svg"/></a>
              <a title="Delete" @click="() => toggleDeleteConfirmationModal(true, props.item)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 }"><img src="@/assets/img/icon-delete.svg"/></a>
            </div>
          </template>
          <AppLoader type="block" :is-active="trainingListLoader"/>
        </AppTable>
        <div class="px-3">
          <AppAlert v-if="trainingListAlert" :type="trainingListAlert.type" :message="trainingListAlert.message"/>
        </div>
      </div>
    </div>
    <div class="app-box">
      <div class="app-box-header">
        <h2 class="app-box-title">Hampir Kadaluarsa</h2>
      </div>
      <div class="app-box-content">
        <AppTable
          :data="trainingExpiredList"
          :columns="[
          {
              name: 'trainingCertificateNumber',
              label: 'No Sertifikat',
              width: '140px',
            },
            {
              name: 'trainingType',
              label: 'Jenis Training',
              width: '350px'
            },
            {
              name: 'trainingExpiredDate',
              label: 'Kadaluarsa Pada',
              isSortable: true
            },
            {
              name: 'trainingStatus',
              label: 'Status',
              isSortable: true,
              align: 'center'
            },
            {
              name: 'actions',
              label: '',
              width: '200px'
            }
          ]"
          :pagination="{
            page: trainingExpiredListPage,
            perPage: trainingExpiredListPerPage,
            totalData: totalTrainingExpiredList,
            perPageOptions: [5, 10],
          }"
          :is-server-side="true"
          @onLazyLoad="(e) => getTrainingExpiredList(e.value)"
        >
          <template v-slot:trainingExpiredDate="props">
            <div class="training-status app-badges">
              {{ (props.value) ? moment(props.value).format('DD MMM YYYY') : 'Tidak ada masa berlaku' }}
              &nbsp;&nbsp;&nbsp;<span class="badge badge-danger">{{ moment(props.value).fromNow() }}</span>
            </div>
          </template>
          <template v-slot:trainingStatus="props">
            <div class="training-status app-badges">
              <span v-if="props.value == '0'" class="badge badge-warning">Menunggu Persetujuan</span>
              <span v-if="props.value == 1" class="badge badge-success">Aktif</span>
              <span v-if="props.value == 2" class="badge badge-danger">Kadaluarsa</span>
              <span v-if="props.value == 3" class="badge badge-danger">Ditolak</span>
              <span v-if="props.value == 3" class="reason-icon ml-2" @click="showRejectedReason(props.item)">
                <img class="reason-icon" src="@/assets/img/icon-reason.svg"/>
                <!-- <div class="reason">
                  <p>Pengajuan ditolak karena : {{props.item.note}}</p>
                </div> -->
              </span>
            </div>
          </template>
          <template v-slot:actions="props">
            <div class="actions">
              <a @click="() => downloadTraining(props.item.id, props.item.trainingCertificate)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 }"><img src="@/assets/img/icon-download.svg"/></a>
              <a @click="() => toggleViewTrainingModal(true, props.item)"><img src="@/assets/img/icon-view.svg"/></a>
              <a @click="() => toggleEditTrainingModal(true, props.item)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 || props.item.trainingStatus == 2 }"><img src="@/assets/img/icon-edit.svg"/></a>
              <a @click="() => toggleDeleteConfirmationModal(true, props.item)" :class="{ 'disabled-icon': props.item.trainingStatus == 0 || props.item.trainingStatus == 2 }"><img src="@/assets/img/icon-delete.svg"/></a>
            </div>
          </template>
          <AppLoader type="block" :is-active="trainingExpiredListLoader"/>
        </AppTable>
        <div class="px-3">
          <AppAlert v-if="trainingExpiredListAlert" :type="trainingExpiredListAlert.type" :message="trainingExpiredListAlert.message"/>
        </div>
      </div>
    </div>

    <AppAlert v-if="trainingActionAlert" :type="trainingActionAlert.type" :message="trainingActionAlert.message" :is-floated="true"/>
    <AppLoader type="screen" :is-active="trainingActionLoader" />
    <AppConfirmModal
      :is-open="isOpenDeleteConfirmationModal"
      confirm-text="Apa kamu yakin ingin menghapus data ini?"
      confirm-label="Hapus"
      reject-label="Batal"
      @rejected="() => toggleDeleteConfirmationModal(false)"
      @confirmed="() => { deleteTraining(trainingToBeDeleted.id, () => { getTrainingList(); getTrainingExpiredList(); });  }"/>
    <AppModal
      modalDialogClass="modal-lg"
      v-if="isOpenEditTrainingModal"
      :is-open="isOpenEditTrainingModal"
    >
      <AppForm
        title="Ubah Training dan Kompetensi"
        :edit="isOpenEditTrainingModal"
        :editData="trainingToBeEdited"
        @close="toggleEditTrainingModal(false)"
        @confirmed="(trainingToBeSubmited) => {toggleEditConfirmationModal(true, trainingToBeSubmited)}"
      />
    </AppModal>
    <AppConfirmModal
      :is-open="isOpenEditConfirmationModal"
      confirm-text="Apa kamu yakin ingin membuat baru berdasarkan data ini?"
      confirm-label="Ubah"
      reject-label="Batal"
      @rejected="() => toggleEditConfirmationModal(false)"
      @confirmed="() => { saveAsNewTraining( () => { getTrainingList(); getTrainingExpiredList(); toggleEditTrainingModal(false) })}"/>
    <AppModal
      modalDialogClass="modal-lg"
      v-if="isOpenViewTrainingModal"
      :is-open="isOpenViewTrainingModal"
    >
      <AppForm
        title="Detail Training dan Kompetensi"
        :viewOnly="isOpenViewTrainingModal"
        :editData="trainingToBeEdited"
        @close="toggleViewTrainingModal(false)"
      />
    </AppModal>


    <AppModal 
        modalDialogClass="modal-sm"
        v-if="showRejectedModal"
        :is-open="showRejectedModal"
    >
      <section class="modal-body">
        <div class="modal-title">Pengajuan ditolak karena:</div>
        <slot name="body">
          {{ rejectedReason }}
          <br>
          <div class="row mr-2" style="float: right;">
            <div class="form-group mb-0">
              <button
                type="button"
                class="btn btn-green"
                @click="showRejectedModal = false"
              >
                Tutup
              </button>
            </div>
          </div>
        </slot>
      </section>
    </AppModal>
  </div>
</template>
<script>
import moment from 'moment'

import AppTable from './../../components/AppTable'
import AppLoader from './../../components/AppLoader'
import AppAlert from './../../components/AppAlert'
import AppConfirmModal from './../../components/AppConfirmModal'
import useTrainingList from './../../composables/training/useTrainingList'
import useTrainingActions from './../../composables/training/useTrainingActions'
import AppForm from './../../components/AppForm'
import AppModal from './../../components/AppModal'

export default {
  name: 'DashboardIndex',
  components: { AppTable, AppLoader, AppAlert, AppForm, AppConfirmModal, AppModal },
  data() {
    return {
      rejectedReason: '',
      showRejectedModal: false,
    };
  },
  setup() {
    const { trainingList, trainingListLoader, trainingListPage, trainingListPerPage, getTrainingList, trainingListAlert, totalTrainingList, trainingExpiredList, trainingExpiredListLoader, trainingExpiredListPage, trainingExpiredListPerPage, getTrainingExpiredList, trainingExpiredListAlert, totalTrainingExpiredList } = useTrainingList();

    const { trainingActionLoader, trainingActionAlert, isOpenCreateTrainingModal, isOpenEditConfirmationModal, isOpenEditTrainingModal, isOpenDeleteConfirmationModal, trainingToBeEdited, trainingToBeDeleted, toggleCreateTrainingModal, toggleEditConfirmationModal, toggleEditTrainingModal, toggleDeleteConfirmationModal, downloadTraining, generateTrainingPdf, deleteTraining, createTraining, saveAsNewTraining, toggleViewTrainingModal, isOpenViewTrainingModal} = useTrainingActions();
    return {
      trainingList,
      trainingListLoader,
      trainingListPage,
      trainingListPerPage,
      getTrainingList,
      trainingListAlert,
      totalTrainingList,

      trainingExpiredList,
      trainingExpiredListLoader,
      trainingExpiredListPage,
      trainingExpiredListPerPage,
      getTrainingExpiredList,
      trainingExpiredListAlert,
      totalTrainingExpiredList,

      trainingActionLoader,
      trainingActionAlert,
      isOpenCreateTrainingModal,
      isOpenEditConfirmationModal,
      isOpenEditTrainingModal,
      isOpenDeleteConfirmationModal,
      isOpenViewTrainingModal,
      trainingToBeEdited,
      trainingToBeDeleted,
      downloadTraining,
      toggleCreateTrainingModal,
      generateTrainingPdf,
      toggleEditConfirmationModal,
      toggleEditTrainingModal,
      toggleDeleteConfirmationModal,
      toggleViewTrainingModal,
      deleteTraining,
      createTraining,
      saveAsNewTraining
    }
  },
  methods: {
    showRejectedReason(item) {
      this.showRejectedModal = true;
      this.rejectedReason = item.note;
    },
    moment(date) {
      return moment(date).locale('id');
    },
    status: function (message) {
      alert(message)
    }
  }
}
</script>
<style lang="scss" scoped>
.reason-icon{
  cursor: pointer;
  position: relative;
}
.reason{
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  width: 170px;
  // height: 131px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  padding:10px;
}

.reason:before{
  content:'';
  border: 20px solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  transform:translateX(-50%) translateY(-120%);
  margin: 0;
  padding: 0;
}

.reason-icon:hover .reason{
  visibility: visible;
  opacity: 1;
  transform : translateX(-50%);
}

.disabled-icon{
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>

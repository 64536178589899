import TrainingService from './../../services/TrainingService'
import { ref, onMounted, reactive } from 'vue'

export default function useTrainingList() {

  const trainingList = reactive([]);
  const trainingListPage = ref(1);
  const trainingListPerPage = ref(5);
  const trainingListLoader = ref(false);
  const trainingListAlert = ref(null);
  const totalTrainingList = ref(0);

  const trainingExpiredList = reactive([]);
  const trainingExpiredListPage = ref(1);
  const trainingExpiredListPerPage = ref(5);
  const trainingExpiredListLoader = ref(false);
  const trainingExpiredListAlert = ref(null);
  const totalTrainingExpiredList = ref(0);  

  const getTrainingList = (obj = null) => {
    trainingListLoader.value = true;
    const page = obj ? obj.currentPage : trainingListPage.value;
    const limit = obj ? obj.limit : trainingListPerPage.value;
    const orderBy = obj ? obj.orderBy : '';
    const orderDirection = obj ? obj.orderDirection : '';
    TrainingService.getList(page, limit, orderBy, orderDirection)
      .then(res => {
        trainingList.splice(0);
        res.training.forEach(element => {
          trainingList.push(element)          
        });
        trainingListPage.value = page;
        totalTrainingList.value = res.totalItems;
      })
      .catch(err => {
        const errData = err.data;
        trainingListAlert.value = {
          type: 'danger',
          message: errData.error.message
        }
      })
      .finally(() => {
          trainingListLoader.value = false;
      })
  }


  const getTrainingExpiredList = (obj = null) => {
    trainingExpiredListLoader.value = true;
    const page = obj ? obj.currentPage : trainingExpiredListPage.value;
    const limit = obj ? obj.limit : trainingExpiredListPerPage.value;
    const orderBy = obj ? obj.orderBy : '';
    const orderDirection = obj ? obj.orderDirection : '';
    TrainingService.getExpiredList(page, limit, orderBy, orderDirection)
      .then(res => {
        trainingExpiredList.splice(0);
        res.training.forEach(element => {
          trainingExpiredList.push(element)          
        });       
        trainingExpiredListPage.value = page;
        totalTrainingExpiredList.value = res.totalItems;
      })
      .catch(err => {
        const errData = err.data;
        trainingExpiredListAlert.value = {
          type: 'danger',
          message: errData.error.message
        }
      })
      .finally(() => {
          trainingExpiredListLoader.value = false;
      })
  }

  onMounted(() => {
    getTrainingList();
    getTrainingExpiredList();
  })

  return {
    trainingList,
    trainingListLoader,
    trainingListPage,
    trainingListPerPage,
    getTrainingList,
    trainingListAlert,
    totalTrainingList,
    trainingExpiredList,
    trainingExpiredListLoader,
    trainingExpiredListPage,
    trainingExpiredListPerPage,
    getTrainingExpiredList,
    trainingExpiredListAlert,
    totalTrainingExpiredList
  }
}
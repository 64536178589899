import TrainingService from './../../services/TrainingService'
import { ref } from 'vue'
import printJS from 'print-js'

export default function useTrainingActions() {

  const trainingActionLoader = ref(false);
  const trainingActionAlert = ref(null);
  const trainingToBeEdited = ref(null);
  const trainingToBeDeleted = ref(null);
  const isOpenCreateTrainingModal = ref(false);
  const isOpenEditConfirmationModal = ref(false);
  const isOpenEditTrainingModal = ref(false);
  const isOpenDeleteConfirmationModal = ref(false);
  const isOpenViewTrainingModal = ref(false);
  
  const downloadTraining = async(id, name) => {
    trainingActionLoader.value = true;
    TrainingService.download(id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        trainingActionAlert.value = {
          type: 'danger',
          message: err.data.error.message
        }        
      })
      .finally(() => {
        trainingActionLoader.value = false;
        setTimeout(() => {
          trainingActionAlert.value = null;
        }, 2000);
      })
  }

  const toggleCreateTrainingModal = (isOpen) => {
    isOpenCreateTrainingModal.value = isOpen;
  }
  
  const generateTrainingPdf = () => {
    trainingActionLoader.value = true;
    TrainingService.generatePdf()
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res], {
          type: 'application/pdf',
        }));
        printJS(url);
      })
      .catch(err => {
        trainingActionAlert.value = {
          type: 'danger',
          message: err.data.error.message
        }        
      })
      .finally(() => {
        trainingActionLoader.value = false;
        setTimeout(() => {
          trainingActionAlert.value = null;
        }, 2000);
      })
  }

  const toggleEditConfirmationModal = (isOpen, training) => {
    isOpenEditConfirmationModal.value = isOpen;
    if(isOpen) {
      trainingToBeEdited.value = training;  
    } else {
      trainingToBeEdited.value = null;
    }
  }

  const toggleEditTrainingModal = (isOpen, training) => {
    isOpenEditTrainingModal.value = isOpen;
    if(isOpen) {
      trainingToBeEdited.value = training;  
    } else {
      trainingToBeEdited.value = null;
    }
  }

  const toggleDeleteConfirmationModal = (isOpen, training) => {
    isOpenDeleteConfirmationModal.value = isOpen;
    if(isOpen) {
      trainingToBeDeleted.value = training;  
    } else {
      trainingToBeDeleted.value = null;
    }
  }

  const toggleViewTrainingModal = (isOpen, training) => {
    isOpenViewTrainingModal.value = isOpen;
    if(isOpen) {
      trainingToBeEdited.value = training;  
    } else {
      trainingToBeEdited.value = null;
    }
  }

  const deleteTraining = (id, onSuccess = () => {}) => {
    const data = {
      trainingId: id
    }
    trainingActionLoader.value = true;
    TrainingService.delete(data)
      .then((res) => {
        trainingActionAlert.value = {
          type: 'danger',
          message: res.data
        }
        onSuccess();
      })
      .catch(err => {
        trainingActionAlert.value = {
          type: 'danger',
          message: err.data.error.message
        }        
      })
      .finally(() => {
        trainingActionLoader.value = false;
        isOpenDeleteConfirmationModal.value = false;
        setTimeout(() => {
          trainingActionAlert.value = null;
        }, 2000);
      })
  }

  const createTraining = (data, onSuccess = () => {}) => {
    trainingActionLoader.value = true;
    TrainingService.postCreate(data)
      .then(() => {
        trainingActionAlert.value = {
          type: 'success',
          message: 'Berhasil menambahkan data'
        }
        onSuccess();
      })
      .catch(err => {
        trainingActionAlert.value = {
          type: 'danger',
          message: err.data.error.message
        }        
      })
      .finally(() => {
        trainingActionLoader.value = false;
        isOpenCreateTrainingModal.value = false;
        // window.location.reload();
        setTimeout(() => {
          trainingActionAlert.value = null;
        }, 2000);
      })
  }

  const saveAsNewTraining = (onSuccess = () => {}) => {
    trainingActionLoader.value = true;
    TrainingService.saveAsNew(trainingToBeEdited.value)
      .then(() => {
        trainingActionAlert.value = {
          type: 'success',
          message: 'Berhasil menyimpan sebagai data baru'
        }
        onSuccess();
      })
      .catch(err => {
        trainingActionAlert.value = {
          type: 'danger',
          message: err.data.error.message
        }        
      })
      .finally(() => {
        trainingActionLoader.value = false;
        isOpenEditConfirmationModal.value = false;
        isOpenEditTrainingModal.value = false;
        // window.location.reload();
        setTimeout(() => {
          trainingActionAlert.value = null;
        }, 2000);
      })
  }

  const uploadTraining = async (file) => {
    let data = new FormData();
    data.append('file', file);

    trainingActionLoader.value = true;
    let result = await TrainingService.upload(data)
    return result
  }

  return {
    trainingActionLoader,
    trainingActionAlert,
    isOpenCreateTrainingModal,
    isOpenEditConfirmationModal,
    isOpenEditTrainingModal,
    isOpenDeleteConfirmationModal,
    isOpenViewTrainingModal,
    trainingToBeDeleted,
    trainingToBeEdited,
    downloadTraining,
    generateTrainingPdf,
    uploadTraining,
    toggleCreateTrainingModal,
    toggleEditConfirmationModal,
    toggleEditTrainingModal,
    toggleDeleteConfirmationModal,
    toggleViewTrainingModal,
    deleteTraining,
    createTraining,
    saveAsNewTraining
  }
}
<template>
  <div class="row m-0">
    <div class="col-sm-12 col-md-6 m-0 p-0 login-left">
      <img src="@/assets/img/login-left.svg" alt="Welcome to iCore"/>
    </div>
    <div class="col-sm-12 col-md-6 login-right">
      <!-- start -->
        <div v-if="errormsg"
          class="text-center alert alert-danger">
          <span>Username / Password yang anda masukkan tidak sesuai</span>
        </div>
      <!-- <div class="error-login">{{ errormsg }}</div> -->
      <div class="login-title">Selamat Datang</div>
      <div class="welcome-message">Silahkan masukkan Username dan Password iSafe Anda.</div>
      <form @submit='login'>
        <div class="form-group">
          <label for="loginUsername">Username</label>
          <input type="text" class="form-control" id="loginUsername" v-model="username">
        </div>
        <div class="form-group">
          <label for="loginPassword">Password</label>
          <input type="password" class="form-control" id="loginPassword" v-model="password">
        </div>
        <div class="form-group">
          <button type="submit" id="loginSubmit" class="btn btn-primary">
            <div v-if="loadingData" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              Masuk
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LoginCard",
  data: () => ({
    username: "",
    password: "",
    errormsg: ""
  }),
  computed: {
    ...mapState(["loadingData"])
  },
  mounted() {
  },
  methods: {
    login(e) {
      e.preventDefault();
      const { username, password } = this;
      this.$store.dispatch("login", { isafeNo: username, password })
      .then(user => {
        if(user) this.$router.push("/dashboard"); 
      })
      .catch(() => this.showError());
    },
    showError() {
      this.errormsg = "username / password salah";
      this.username = '';
      this.password = '';
    }
  }
};
</script>